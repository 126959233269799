/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
// WebPack generation of HTML files under `/demo`
// import './demo/index.html';
import React from 'react';
import { ThemeProvider, audiLightTheme } from '@audi/audi-ui-react';
import { FeatureAppDefinition, FeatureServices, Logger } from '@feature-hub/core';
import { FocusLayerSizeV2, LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { ReactFeatureApp } from '@feature-hub/react';
import OneLayerCollection from './components/one-layer-collection';
import { LayerContentHTML } from './utils';

export const FEATURE_APP_NAME = 'oneaudi-feature-app-one-layer';

export interface Dependencies extends FeatureServices {
  readonly 's2:logger': Logger;
  readonly 'layer-manager': LayerManagerV27;
}

interface Config {
  zIndexAlert: number;
  zIndex?: number;
}

type StaticFalconConfig = {
  __type?: 'aem-headless';
  fields: {
    zIndexAlert?: number;
    zIndex?: number;
  };
};

type FeatureAppDefinitionType = FeatureAppDefinition<ReactFeatureApp, Dependencies, Config>;

function normalizeConfig(config?: Config | StaticFalconConfig): Config | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config.__type === 'aem-headless') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return config.config.fields as Config;
  }
  return config as Config;
}

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices: services, config: rawConfig, featureAppId }) => {
    const config = normalizeConfig(rawConfig);
    const zIndexAlert = config?.zIndexAlert || 100;
    const zIndex = config?.zIndex;

    const layerManager = services['layer-manager'];

    if (typeof window !== 'undefined') {
      const urlHash = window.location.hash;
      const currentUrl = new URL(window.location.href);
      const { protocol, host, pathname } = currentUrl;
      const hasDeepLink = urlHash.includes('one-layer');
      const url = urlHash.replace('#one-layer=', '');
      const isProtocol = url.startsWith('audi') ? `${protocol}/` : '';
      const htmlUrl = `${isProtocol}/${url}`;

      if (hasDeepLink && htmlUrl && layerManager) {
        // Protocol and Host need to be filled in because url is is written without them
        // like href='/de/brand/de/tools/navigation/layer/impressum.html'
        layerManager.openFocusLayer(
          () => <LayerContentHTML enableDeepLinking url={htmlUrl} />,
          {},
          {
            size: FocusLayerSizeV2.A,
            userCloseable: true,
            // Go back to a URL without a one-layer query
            onClose: () => window.location.replace(`${protocol}//${host}${pathname}`)
          }
        );
      }
    }

    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App,
      // that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <ThemeProvider theme={audiLightTheme}>
          <OneLayerCollection
            {...{ featureAppId, zIndex, zIndexAlert }}
            layerManager={layerManager}
          />
        </ThemeProvider>
      )
    };
  },

  dependencies: {
    featureServices: {
      'layer-manager': '^2',
      's2:logger': '^1.0.0'
    },
    externals: {
      react: '^18.2.0',
      '@audi/audi-ui-react': '^3',
      '@feature-hub/react': '^3',
      'styled-components': '^5.3.11'
    }
  },

  optionalDependencies: {
    featureServices: {}
  }
};

export default featureAppDefinition;
